@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap");

body {
  overflow-x: hidden;
  font-family: "Lato", sans-serif;
}

textarea:focus,
input:focus,
button:focus {
  outline: none;
}
/* navigationbar */
nav {
  box-shadow: 6px 6px 35px -9px rgba(0, 0, 0, 0.75);
}

tbody > tr {
  height: 110px;
}

.modal {
  .card {
    width: 350px;
    height: 180px;
  }

  .add-question-card {
    width: 67vw;
    height: auto;
    max-width: 700px;
  }
}

.layout-wrap {
  padding-left: 200px;
}

@media only screen and (max-width: 765px) {
  .layout-wrap {
    padding-left: 0px;
  }
}

.safe-area {
  padding: 0 30px;
  width: 100%;
  max-width: 85%;
  margin: 0 auto;
}

@media only screen and (max-width: 1300px) {
  .safe-area {
    padding: 0 20px;
    max-width: 90%;
  }
}
  
.bottomPadding{
  padding-bottom: 40px;
}

.exportCSV{
  background: rgb(40,184,152);
  background: linear-gradient(135deg, rgba(40,184,152,1) 0%, rgba(79,197,222,1) 100%);
  border-radius:4px;
  border:1px solid #18ab29;
  display:inline-block;
  cursor: pointer;
  color:#ffffff;
  font-size: 15px;
  padding:9px 42px;
  text-decoration:none;
  text-shadow:0px 1px 0px #2f6627;
  margin-bottom: 60px;
  margin-right: 150px;
  position: absolute;
  right:    0;
  bottom:   0;
}

.guid {
  background-color: #7b79794d;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;
  // overflow: auto;
  // width: 100vh;
  // height: 100vh;
  .helper {
    background-color: ghostwhite;
    background: #138eac;
    position: absolute;
    color: white;
    padding: 10px;
    border-radius: 5px;
  }

  .store-guid-item {
    margin-bottom: 5px;
    text-decoration: underline;
    cursor: pointer;
  }
}

.table-responsive {
  padding: 15px;
}

// .navbar {
//   height: 70px;
// }

.avatar img {
  border-radius: 50px;
}

.log-out {
  font-size: 18px;
  cursor: pointer;
  border-radius: 3px;
  background-color: #f1f1f1;
  margin-top: 10px;
  padding: 10px;
  display: inline-block;
  width: 100%;

  &:hover {
    background-color: #f1f1f1;
  }
}

.avatar img:hover {
  transform: scale(1.02);
  transition: 0.5s all;
  cursor: pointer;
}

.nav-chat {
  font-size: 30px;
  margin-right: 20px;
  color: #808080;
}

.Toastify__toast-body {
  padding-left: 20px;
}

.Toastify__toast-container {
  text-transform: uppercase;
  font-size: 11px;
  font-weight: bold;
}

.Toastify__close-button {
  margin-right: 5px;
  margin-top: 1px;
  font-size: 15px;
}

.nav-chat:hover {
  text-decoration: none;
  color: #868686;
  transform: scale(1.01);
  transition: 0.5s all;
}

// Side Nav
.pcid-logo {
  display: block;
  margin: 0 auto;
}

.sidenav {
  height: 100%;
  width: 200px;
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0;
  background-color: #e8e8e8;
  overflow-x: hidden;
  padding-top: 35px;
}


.sidenavDiv {
  padding: 10px 5px 5px 0px;
  text-decoration: none;
  font-size: 17px;
  color: black;
  display: block;
  padding-left: 25px;
  text-align: left;
  padding-top: 5px !important;
  font-weight: 400;
  transition: all 0.05s;
  
  &.active {
    color: #000000;
    background-color: #f7f7f7 !important;
    font-weight: 400;
  }

  &:hover {
    img {
      filter: none !important;
    }
    background-color: #f7f7f7;
  }
}

.sidenavMenu{
  img {
    filter: none !important;
  }  
}

.toggledNav{
  background-color: lightgrey;
}

.sidenavToggle{
  display: flex;
  font-size: 17px;
  padding: 15px 5px 15px 15px;
  text-decoration: none;
  font-size: 17px;
  color: black;
  padding-left: 25px;
  text-align: left;
  font-weight: 400;
  transition: all 0.05s;
  width: 100%;

  &:hover{
    color: #000000;
    background-color: #f7f7f7;
    cursor: pointer;
  }
  
  div{
    margin-top: -2px;
  }

  &.show{
    margin-bottom: 20px;
  }

  img {
    filter: invert(60%) sepia(13%) saturate(2042%) hue-rotate(117deg) brightness(98%) contrast(90%);
  }
}



.sidenavDiv {
  padding: 10px 5px 5px 0px;
  text-decoration: none;
  font-size: 17px;
  color: black;
  display: block;
  padding-left: 25px;
  text-align: left;
  padding-top: 5px !important;
  font-weight: 400;
  transition: all 0.05s;
  
  &.active {
    color: #000000;
    background-color: #f7f7f7 !important;
    font-weight: 400;
  }

  &:hover {
    img {
      filter: none !important;
    }
    background-color: #f7f7f7;
  }
}

.sidenavMenu{
  img {
    filter: none !important;
  }  
}

.toggledNav{
  background-color: lightgrey;
}

.sidenavToggle{
  display: flex;
  font-size: 17px;
  padding: 15px 5px 15px 15px;
  text-decoration: none;
  font-size: 17px;
  color: black;
  padding-left: 25px;
  text-align: left;
  font-weight: 400;
  transition: all 0.05s;
  width: 100%;

  &:hover{
    color: #000000;
    background-color: #f7f7f7;
    cursor: pointer;
  }
  
  div{
    margin-top: -2px;
  }

  &.show{
    margin-bottom: 20px;
  }

  img {
    filter: invert(60%) sepia(13%) saturate(2042%) hue-rotate(117deg) brightness(98%) contrast(90%);
  }
}


.sidenav a {
  padding: 15px 5px 15px 15px;
  text-decoration: none;
  font-size: 17px;
  color: black;
  display: block;
  padding-left: 25px;
  text-align: left;
  font-weight: 400;
  transition: all 0.05s;

  img {
    filter: invert(60%) sepia(13%) saturate(2042%) hue-rotate(117deg) brightness(98%) contrast(90%);
  }

  &.active {
    color: #000000;
    background-color: #f7f7f7;
    font-weight: 600;
  }

  &:hover {
    img {
      filter: none !important;
    }
  }
}
.__react_component_tooltip {
  max-width: 500px;
}

.table thead th {
  vertical-align: middle;
}

.tooltip-wrap {
  float: left;
  color: #6c757d;
  font-size: 22px;
}

.img-remove {
  position: absolute;
  bottom: -20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 50%;
  width: 32px;
  background-color: transparent;
  color: #dc3545;
  border-color: transparent;
  background-color: white;
  border: 1px solid lightcoral;
}

.add-new-manager-btn {
  float: right;
  background: #e4e4e4;
  border-radius: 1px;
  margin-top: 5px;
  padding: 0 5px;
}

.sidenav a:hover {
  color: #000000;
  // font-weight: 600;
  background-color: #f7f7f7;
}

.sidenav a img {
  margin-top: -5px;
}

.sidenav a.active img {
  filter: invert(60%) sepia(13%) saturate(2042%) hue-rotate(117deg) brightness(98%) contrast(90%);
}

.active-icon {
  filter: invert(60%) sepia(13%) saturate(2042%) hue-rotate(117deg) brightness(98%) contrast(90%);
}

.table thead th {
  font-weight: 400;
  text-align: center;
  font-weight: 500;
}

.table td {
  text-align: center;

  .link-color a {
    color: #4591ff;
  }
}

.file-button-wrap {
  text-align: center;
  position: relative;
  .badge {
    margin-bottom: 5px;
    width: 100%;
    background: transparent;
  }
}
.filebutton {
  i.fas.fa-camera {
    font-size: 25px;
    color: #777777;
  }
}

.MuiPickersToolbar-toolbar {
  background-color: #5cc3b6 !important;
}

.MuiPickersDay-daySelected {
  background-color: #5cc3b6 !important;
}
.best-calendar {
  height: 40px;
  width: 100%;
  padding-left: 10px;

  .textbox {
    position: absolute;
    z-index: 5;
    float: left;
  }
  .inline {
    // float: left;
    color: transparent !important;
    position: absolute;
    z-index: 1;
    input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
      display: block;
      opacity: 0;
      border: 0;
      float: left;

      &:active {
        display: inline;
      }
    }
    .MuiInput-underline:before {
      display: none;
    }
  }
}


.map-grid{
  display: grid;
  // grid-template-columns: 38px 75px 200px 160px 240px 150px 280px;
  grid-template-columns: minmax(6%, 50px) minmax(9%, 100px) minmax(15%, 160px) minmax(17%, 160px) minmax(21%, 160px) minmax(20%, 240px);
  align-items: center;
  text-align: left;
  
  height: 60px;
  text-align: center;
  
}
.map-auto{
  // .calendar{
  //   background-color: #000000;
  // }
}

.calendarStores{
  margin: 0 auto;
  z-index: 1 !important;
  width: 230px;
  margin-top: -18px;

  .store-calendar{
    margin-left: 40px;
    margin-right: -30px;
  }
  .MuiInput-underline:before {
    display: none;
    content: none !important;
    border-bottom: 0px !important;
  }
  
}

.store-calendar {
  height: 40px;
  border: none !important;
  &:active{
    outline: none !important;
  }
  .textbox {
    z-index: 5;
  }
  .inline {
    // float: left;
    z-index: 1;
    input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
      display: block;
      opacity: 0;
      float: left;
      

    }
    .MuiIconButton-label{
      margin-top: -115px;
      margin-right: -10px;
    }

    .MuiInput-underline:before {
      display: none;
      content: none !important;
      border-bottom: 0px !important;
    }
  }
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl{
  width: 65%;
}
.cursor-pointer {
  cursor: pointer !important;
}

.breadcrumb {
  background-color: #e4e4e4;
  font-size: 18px;
  text-transform: uppercase;
  margin: 0;
  margin-right: 10px;
  margin-top: 5px;

  &:hover {
    background-color: #d6d6d6;
  }

  .text-secondary {
    color: #000000 !important;
    // margin-top: 1px;
    // line-height: 11px;
    // display: inline-block;
    // float: right;
  }
}

.breadcrumb-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

button.btn-messages {
  border: none;
  background: none;
  font-size: 27px;
  color: gray;
  position: relative;
}

.sidenav::-webkit-scrollbar { width: 0 !important }

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;

  }
  .sidenav a {
    font-size: 18px;
  }
}

@media only screen and (max-width: 765px) {
  .sidenav {
    width: 0;
    transition: 0.3s all;
    opacity: 0;

    &.active {
      width: 200px;
      box-shadow: 1px 1px 9px #d0d0d0;
      z-index: 99;
      opacity: 1;
    }
  }

  // .safe-area {
  //     margin-left: 0;
  //     width: 100%;
  // }
}

// End of Side Nav

/* Center Screen */
.center-screen {
  margin-top: 10vh;
  // margin-bottom: 20vh;
}

/* Common Buttons */
.btn-pcid {
  // border-radius: 10px !important;
  background-image: linear-gradient(to right, #6ac07a, #5ec5bf);
  border: none;
  color: black;
  padding: 5px 20px;
  font-weight: 500;

  &:hover {
    color: white;
  }
}

.payment-btn.btn {
  background: #ededed;
  color: #252525;
  border-radius: 7px;
  border: none;

  .fab {
    margin-right: 5px;
  }

  &:hover {
    background: #6ec177;
    color: white;
  }
}

.impersonate-user {
  .login-as-row {
    border: 1px solid lightgray;
    margin-top: 5px;
    padding: 5px;
    background: #e8e8e8;
    border-radius: 3px;
  }

  .company-name {
    background-color: transparent;
    color: gray;
    font-size: 20px;
    font-weight: lighter;
  }

  .company-switch-row {
    background: transparent;
  }
}

.btn-pcid:focus,
.btn-pcid-cancel:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-pcid-cancel {
  border-radius: 10px;
  border: none;
  font-size: 15px;
}

.top-version-number {
  position: absolute;
  bottom: -8px;
  font-size: 10px;
  background: #a0a0a0;
  padding: 0 5px;
  border-radius: 3px;
  color: white;
  right: -21px;
  pointer-events: none;
}

.current-company-alert {
  padding: 10px;
  background-color: #f57171;
  color: white;
  border-radius: 5px;
  font-weight: bold;

  .btn {
    margin: 0 10px;
  }
}

/* Image Upload */
label.filebutton {
  width: 100px;
  height: 100px;
  overflow: hidden;
  position: relative;
  background-color: #ccc;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
}

.msg-wrap {
  position: relative;

  .msg-profile {
    max-height: 50vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .message-user-list {
    position: absolute;
    left: -300px;
    width: 350px;
    top: 47px;
    background: white;
    text-align: left;
    overflow: hidden;
    box-shadow: 0px 1px 4px grey;

    .footer {
      height: 40px;
    }

    .header {
      background: #e8e8e8;
      border-bottom: 1px solid gray;
      font-size: 17px;
      padding: 10px 22px;
      text-align: left;
      color: black;
      font-weight: bold;
    }

    .user-lane {
      font-size: 10px;
    }

    .last-msg-time {
      font-size: 12px;
    }

    .msg-row {
      border-bottom: 1px solid gray;
      padding: 10px 5px;
      text-overflow: ellipsis;
      cursor: pointer;

      &:hover {
        background-color: #d6d6d6;
      }
    }

    .username-wrap {
      font-size: 16px;
      font-weight: bold;
    }
    .username {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .last-msg {
      font-size: 12px;
    }

    .profile-image {
      width: 50px;
      height: 50px;
    }
  }
}

.company-switch-row {
  padding: 10px;
  background: #e8e8e8;
}

.company-name {
  background: #61c3a9;
  padding: 5px;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  .badge {
    font-size: 10px;
    color: #f8f9fa;
  }
}
.btn {
  border-radius: 18px;
}

.cropper-wrap {
  position: absolute;
  left: -22%;
  right: 0;
  top: 0;
  bottom: 0;
  width: 400px;
  z-index: 9999;
  margin: 0 auto;

  .cropper-inner {
    background: white;
    padding: 0px;
    // width: 50%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    box-shadow: 0px 0px 4px 1px black;
  }

  .cropper-component {
    position: relative;
    width: 100%;
    height: 350px;
  }
}

.sign-up {
  .cropper-wrap {
    left: -136px;
  }
}

.message-full {
  width: 350px;
  position: fixed;
  bottom: 9px;
  height: 450px;
  background: white;
  box-shadow: 1px 1px 4px grey;
  right: 20px;
  padding: 10px;
  border-radius: 3px;
  padding: 0;
  z-index: 5;

  .loading {
    position: absolute;
  }

  .footer {
    height: 52px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    border-top: 2px solid lightgray;
    background: #efefef;
  }

  // display: flex;
  // flex-direction: column;

  .content-wrap {
    height: 340px;
    overflow-y: scroll;
  }

  .lane-info {
    font-size: 12px;
    color: gray;
  }

  .name {
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 137px;
  }

  .messages {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
  }

  .msg {
    padding: 10px;
    background: white;
    margin: 10px;
    border-radius: 5px;
    position: relative;
    padding-right: 76px;
    padding-bottom: 20px;
    box-shadow: 1px 1px 2px #a7a7a7;
    font-weight: bold;
    align-self: flex-start;

    .sender.badge {
      margin-left: -4px;
      margin-bottom: 5px;
      color: #a0a0a0;
    }

    &.me {
      align-self: flex-end;
      background: #dcf8c6;

      .sender.badge {
        display: none;
      }
    }

    .time {
      position: absolute;
      bottom: 3px;
      right: 3px;
      color: #888888;
      font-weight: 400;
    }
  }

  button.close-message {
    border: none;
    background: none;
    color: gray;
  }

  .content {
    display: flex;
    flex-direction: column;
    background: #e6e6e6;
  }
  .msg-input {
    border: 1px solid lightgray;
    width: 100%;
    border-radius: 5px;
    height: 40px;
    padding: 5px;
  }
  .send-button {
    border: none;
    background: #63c4a3;
    color: white;
    border-radius: 50%;
    padding: 10px;
    width: 44px;
    margin-right: 4px;
    margin-left: 10px;
  }
  .header {
    background: white;
    padding: 15px;
    border-bottom: 2px solid lightgray;
  }
}

label.filebutton:hover {
  background-color: rgb(121, 121, 121);
  transition: all 0.5s;
}

.badge-info.version {
  color: #5bc5bf;
  text-align: center;
  margin: 0 auto;
  display: inline-block;
  text-align: right;
  position: absolute;
  bottom: 12px;
  right: 30px;
  opacity: 0.7;
  letter-spacing: 1px;
  /* left: 0; */
  width: 50px;
  font-size: 11px;
  font-weight: normal;
  text-align: right;
  background-color: #ffffff;
}

.result-row {
  padding: 10px;
  /*background-color: #e8e8e8;*/
  margin-bottom: 3rem;
  margin-left: 0.3rem;
}

.dropdown-toggle {
  background: white;
  border: none !important;
  color: black !important;

  &:hover,
  &:active {
    background: transparent !important;
    border: none !important;
    color: black !important;
  }
}

.show > .btn-primary.dropdown-toggle {
  background-color: transparent;
}

.dropdown-toggle::after {
  content: none !important;
}

.tooltip-text {
  font-size: 15px;
  top: 40px;
  display: none;
  position: relative;
}

.white-tooltip-text {
  font-size: 12px;
  top: 40px;
  display: none;
  color: #ffffff;
  position: relative;
}

label span input {
  z-index: 999;
  line-height: 0;
  font-size: 50px;
  position: absolute;
  top: -2px;
  left: -700px;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: "alpha(opacity=0)";
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.pro-pic {
  display: flex;
  justify-content: center;
}

.pro-pic .fas.fa-camera {
  font-size: 30px;
  opacity: 0.5;
}

.table-v-center {
  td {
    vertical-align: middle;
    padding: 0;
  }
}

.dropdown-toggle {
  &.btn {
    border-radius: 3px;
    background: transparent;
  }
}


.dashboard {
  
  .DashboardActions{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dashboard-icon-wrap {
    text-align: center;
    display: inline-block;
    background-color:transparent;
    border-radius:10px;
    display:inline-block;
    cursor:pointer;
    font-family:Arial;
    font-size:14px;
    margin-right: 20px;
    width: 190px;
    text-transform: capitalize;
    &.blue {
      color: #2b96df;
      border:2px solid #2b96df;
    }
    &.green {
      color: #4aaf8a;
      border:2px solid #4aaf8a;
    }
    &.purple {
      color: #ad6ec5;
      border:2px solid #ad6ec5;
    }
    &.orange {
      color: #ff9648;
      border:2px solid #ff9648;
    }
    &.red {
      color: #ff596e;
      border:2px solid #ff596e;
    }

    img{
      width: 38px;
      display: inline;
      margin-bottom: 2px;
    }
    .icontext{
      display: inline-block;
      padding-left: 20px;
      padding-top: 5px;
    }
    .icon-wrap {


      .icon {

      }
      &.green {
        background-color: #4aaf8a;
      }
      &.purple {
        background-color: #975793;
      }
      &.orange {
        background-color: #f0843c;
      }
      &.red {
        background-color: #e64441;
      }
    }

    .label {
    }
  }

  .dashboardBlock{
    display: flex;
    height: 300px;
    border:2px solid lightGray;
    margin-top: 30px;

    a{
      color: #4591ff;
    }
    
    .title{
      font-size: 22px;
      font-weight: bold;
      margin-top: 15px;
      margin-left: 30px;

    }
    span{
      margin-left: 10px;
    }
  }
}
  .dashboardBlocks2{
    width: 68%;
    
          /* width */
          ::-webkit-scrollbar {
            width: 8px;
            
          }
          
          /* Handle */
          ::-webkit-scrollbar-thumb {
            border-radius: 6px;
            background: lightgray;
          }
  }

  .DashboardLegend{
    display: flex;
    margin-left: 40px;
    margin-top: 40px;
    
    div{
      display: flex;
      margin-right: 20px;
      p{
        margin-left: 8px;
      }
      span:hover{
        cursor: pointer;
      }

      .legend{
        margin-top: 7px;
        height: 12px;
        width: 12px;
        border-radius: 50%;
        display: inline-block;
    
        &.today-default{
          border: 1px solid #fff;
          box-shadow: 0 0 0 2px #ff8931;
          background-color: #ff8931;
        }
        &.today-clicked{
          border: 6px solid #fff;
          box-shadow: 0 0 0 2px #ff8931;
          background-color: #ff8931;
        }
    
        &.soon-default{
          border: 1px solid #fff;
          box-shadow: 0 0 0 2px #ffb93c;
          background-color: #ffb93c;
        }
        &.soon-clicked{
          border: 6px solid #fff;
          box-shadow: 0 0 0 2px #ffb93c;
          background-color: #ffb93c;
        }

        &.late-default{
          border: 1px solid #fff;
          box-shadow: 0 0 0 2px #ff485e;
          background-color: #ff485e;
        }
        &.late-clicked{
          border: 6px solid #fff;
          box-shadow: 0 0 0 2px #ff485e;
          background-color: #ff485e;
        }
        
        &.failed-default{
          border: 1px solid #fff;
          box-shadow: 0 0 0 2px #c43957;
          background-color: #c43957;
        }
        &.failed-clicked{
          border: 6px solid #fff;
          box-shadow: 0 0 0 2px #c43957;
          background-color: #c43957;
        }

        &.done-default{
          border: 1px solid #fff;
          box-shadow: 0 0 0 2px #00be9c;
          background-color: #00be9c;
        }
        &.done-clicked{
          border: 6px solid #fff;
          box-shadow: 0 0 0 2px #00be9c;
          background-color: #00be9c;
        }

    }


  }

  }

  th, td {
    padding-left: 10px !important;
    text-align: center;
    .sortable{
      text-align: center;
    }
  }
  .column-search{
    border: none;
    padding-left: 0px !important;
    width: 70px;
    background-color:#f4f4f4;
  }

  .status{
    height: 12px;
    width: 12px;
    border-radius: 50%;
    display: inline-block;

    &.today{  
      background-color: #ff8931;
    }

    &.soon{
      background-color: #ffb93c;
    }
    &.late{
      background-color: #ff485e;
    }
    &.failed{
      background-color: #c43957;
    }
    &.done{
      background-color: #00be9c;
    }

  }

  .inspectionStatus{
    .status{
      margin-right: 10px;
    }
  }

  .dashboardTable{
    width: 100%;
    
  }

  .dashboardTable{
    width: 100%;
    
  }

  .dashboardTable{
    display: block;
    width: 100%;
    max-height: 200px;
  }

  .DashboardGridTitles{
    
    border-bottom: 2px solid gray;
    th{
      width: 500px;
    }

    .sortable{
      margin: 0%;
      padding-bottom: 15px;
      font-weight: bold;
    }
  }

  .InspectionGridTitles{
    position: sticky;
    top: 0px;
    margin-top: 100px;
    border-bottom: 2px solid gray;
    th{
      width: 500px;
    }

    .sortable{
      margin: 0%;
      padding-bottom: 15px;
      font-weight: bold;
    }
  }

  .InspectionTable{
    display: block;
    width: 100%;
    background-color: white;
  }
  
  .InspectionGrid::-webkit-scrollbar {width: 8px}
  .InspectionGrid::-webkit-scrollbar-thumb {
      border-radius: 6px;
      background: lightgray;
    }
  .InspectionGrid{
    display: block;
    height: 60vh;
    overflow-y: scroll;

    tr{
      height: 55px;
    }
    td, th{
      width: 500px;
    }
  }


  .DashboardGrid{
    display:block;
    max-height: 155px !important;
    overflow-y: auto;


    tr{
      height: 55px;
    }
    td, th{
      width: 500px;
    }
    
    
    

  }

  .Doughnut{
    width: 400px;
    margin-left: -45px;
    margin-top: 20px;
  }

.mw-50 {
  max-width: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.add-image:hover {
  transform: scale(1.1);
  cursor: pointer;
  transition: ease-in 0.2s;
}

.toggle-additional-images {
  border: 1px solid gainsboro;
  border-radius: 3px;
  .fas {
    margin-left: 5px;
  }
}

.add-verification-section {
  background-color: #f3f4f5;
  padding: 25px 10px 25px 10px;
  border-radius: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.overlay {
  padding: 40px;
}

.profile-image {
  width: 70px;
  height: 70px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  // border: 3px solid lightgray;
  border-width: 0;
  background-color: white;

  &.small {
    width: 50px;
    height: 50px;
  }

  &:hover {
    border-color: #5c8dbf;
  }
}

.small {
  &.pro-pic .badge-info {
    display: inline;
    border: none;
  }
  .profile-image {
    width: 40px;
    height: 40px;
  }
}

.external-link-icon {
  margin-left: -10px;
}

.view-attachment {
  position: absolute;
  left: 0;
  right: 0;
  top: 31px;
  font-weight: bold;
  color: white;
  background: #86d2d0;
  width: 72px;
  margin: 0 auto;
}

.filebutton:hover .overlay {
  position: relative;
  background-color: rgb(41, 41, 41);
  opacity: 0.6;
  margin-top: -8px;
  transition: ease-in 0.5s;
}

.card-header {
  .badge {
    color: black;
  }
}

.additional-image-thumbnail {
  transition: ease-out 0.5s;
}

.file-button-wrap {
  margin-bottom: 15px;
}

.additional-image-thumbnail {
  margin-top: -175px;
}

/* Card header */
.card-header {
  background-image: linear-gradient(to right, #6ac07a, #5ec5bf);
  border: none;
  color: #000000;
}

/* Common topic */
.topic-text {
  font-weight: 400;
}

/* Common inputs */
@media only screen and (min-width: 1200px) {
  form {
    // padding-left: 100px;
    // padding-right: 100px;
  }
}

.form-control {
  border-radius: 5px !important;
  border: 2px solid gray;
  background-color: #ffffff;
}

.filter-form-control {
  border-radius: 5px !important;
  border: 2px solid gray;
  background-color: #ffffff;
  font-size: 12px;
}

/* Sub topic underline */
hr.underline {
  border: 1px solid gray;
  margin-top: -20px;
}

/* Tables */
.table-edit {
  text-decoration: none;
  color: #000000;
}

.table-edit:hover {
  text-decoration: none;
  color: #2b2b2b;
}

.table tbody .large-icon {
  font-size: 20px;
}

.table-links:hover {
  text-decoration: none;
}

.badge-info {
  color: #6c757d;
  background-color: #e8e8e8;
}

.pro-pic .badge-info {
  position: absolute;
  z-index: 1;
  /* left: 0; */
  /* right: 0; */
  /* top: -5px; */
  width: auto;
  margin: 0 auto;
  border: 1px solid black;
}

tbody {
  .colored-row {
    border-bottom: 10px solid #ffffff;
  }
}

.pro-pic {
  position: relative;
  margin-top: 5px;
}

a {
  cursor: pointer;
  color: #495057;
  font-weight: bold;

  &:hover {
    text-decoration: none;
    color: #000000;
  }
}

tbody > tr:hover {
  cursor: default;
}

.colored-row {
  background-color: #f4f4f4;
}

.colored-row,
.colored-dark-row {
  .empty {
    
    background-color: white;
  }
}

td.inner-row:not(:empty) {
  background: gainsboro;
  padding: 10px;
  border-radius: 20px;
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;
}

.lane-section{
  /* width */
  ::-webkit-scrollbar {
   height: 12px;
  }


  /* Handle */
  ::-webkit-scrollbar-thumb {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    background: lightgray;
  }
}

.table-space {
  margin-top: 50px;
}


td {
  .inner {
    height: 55px;
    align-items: center;
  }
}

// tbody {
//   tr.lane-section {
//     &:nth-child(2) {
//       padding-top: 10px;
//     }
//   }
// }

.question-item {
  padding: 10px;
  margin-bottom: 5px;

  a {
    font-weight: 400;
  }
}

.add-item {
  height: 50px;
}

.lane-section {
  height: auto;
  min-width: 2041;
  // border-bottom: 1px solid #c7c7c7;

  .row {
    align-items: center;
  }
}

.icon-green {
  color: #26b99a;
  font-size: 23px;
}

th,
td {
  font-weight: normal;
  font-size: 15px;
}

.table-add-link {
  text-decoration: none;
  color: #000000;

  .row .col-auto {
    margin: 0;
    padding: 0;
  }
  .row > .col-auto:first-child {
    width: 50px;
  }
}

.table-add-link:hover {
  text-decoration: none;
  color: #2b2b2b;
}

table {
  a {
    color: #4591ff;
    font-weight: normal;
  }
}

/* Modals */
.modal-text {
  font-weight: normal;
}

.modal {
  border-radius: 0 !important;
}

.modal-backdrop.show {
  background-color: #000000;
  opacity: 0.8 !important;
}

.modal-text {
  font-weight: 400;
}

.modal {
  border-radius: 0 !important;
}

.modal-image {
  width: 720px;
  margin-left: -17px;
  margin-bottom: -17px;
}

.modal-lg {
  width: 720px !important;
}

.modal-link {
  font-weight: 100;
}

.modal-link:hover {
  text-decoration: none;
}

.modal-delete-button {
  color: rgb(82, 79, 79);
}

.modal-delete-button:hover {
  color: rgb(56, 56, 56);
  text-decoration: none;
}

.modal-title-text {
  font-weight: 100;
}

.modal-body {
  overflow: hidden !important;
}

.modal-close-button {
  margin-left: 50px;
  font-size: 30px;
  font-weight: 600;
}

/* Datepicker */
.datepicker {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: #eee
    url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
    right 25px top 50% no-repeat;
  padding: 10px 20px;
  border-radius: 60px;
  font-size: 1.1rem;
  position: relative;
}

/* Dropdowns */
.btn-dropdown,
.btn-dropdown:hover {
  background-color: #ffffff;
  border-color: #ffffff;
  color: #000000;
}

.btn-dropdown:active,
.btn-dropdown:focus {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #000000 !important;
}

.dropdown-item {
  font-weight: 400;
  padding: 10px;
  font-size: 12px;
}

.dropdown {
  margin-top: -5px;
}

/* Message dropdown */

.message-count {
  position: absolute;
  border-radius: 50%;
  background: red;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  color: white;
  font-weight: bold;
  top: -8px;
  right: 11px;
  z-index: 1;
  text-align: center;
}

.dropbtn {
  color: white;
  border: none;
  cursor: pointer;
  position: relative;
}

.message-dropdown {
  position: relative;
  display: inline-block;
  margin-top: 50px;
  margin-right: 20px;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 410px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: -390px;
  margin-top: -60px;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.show {
  display: block;
}

.message-date {
  font-size: 10px;
}

.message-username {
  font-size: 20px;
}

.message-time {
  font-size: 10px;
  font-weight: 100;
}

.message-body {
  font-size: 12px;
  color: gray;
}

.message-online-status {
  border-radius: 25px;
  background-color: green;
  height: 10px;
  width: 10px;
  margin-left: 10px;
}

.message-line {
  margin-top: -10px;
}

/* Chat box */
.open-button {
  background-color: rgb(255, 255, 255);
  color: #000000;
  padding: 16px 20px;
  border: 2px solid gray;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 0px;
  right: 28px;
  width: 280px;
  border-radius: 5px 5px 0 0;
  position: fixed;
}

.chat-popup {
  display: none;
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 3px solid #f1f1f1;
  z-index: 9;
  width: 360px;
}

.form-container {
  max-width: 350px;
  padding: 10px;
  background-color: white;
}

.form-container #msg {
  width: 100%;
  padding: 15px;
  margin: 5px 0 -10px 0;
  border: none;
  background: #ffffff;
  resize: none;
}

.form-container #msg:focus {
  outline: none;
}

.form-container textarea:focus {
  background-color: #ddd;
  outline: none;
}

.form-container .btn {
  background-color: #4caf50;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

.cancel-button {
  background-color: #ffffff;
  border: none !important;
  font-weight: 900;
}

.cancel-button:focus {
  outline: none;
}

.form-container .cancel {
  background-color: red;
}

.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}

.received-message {
  border: 2px solid gray;
  border-radius: 20px;
}

.received-message p {
  text-align: left;
  padding: 10px;
  font-size: 12px;
}

.sent-message {
  border-radius: 20px;
  margin-top: 10px;
}

.sent-message p {
  text-align: right;
  padding: 10px;
  font-size: 12px;
  border-radius: 20px;
}

.sent-message-body {
  background-image: linear-gradient(to right, #6ac07a, #5ec5bf);
}

/* Avatar Dropdown */
a#avatar-icon {
  padding: 10px;
  top: -3px;
}

.avtr-dropbtn {
  color: white;
  border: none;
  cursor: pointer;
  position: relative;
}

.avatar-dropdown {
  position: relative;
  display: inline-block;
  margin-right: 20px;
}

.avatar-dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 200px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: -150px;
  margin-top: 0px;
}

.avatar-dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.avatar-show {
  display: block;
}

.message-line {
  margin-top: -10px;
}

.avatar-image {
  border-radius: 50px;
  z-index: -1;
  position: relative;
}

.upload-image {
  height: 100px;
}

.dropdown-menu.show {
  transform: translate(35px, -10px) !important;
 
}

.sortable {
  cursor: pointer;

  &:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    margin-right: 3px;
    color: gray;
  }

  &[data-sorted="true"] {
    &:before {
      content: "\f0de";
    }
    color: black;
  }
  &[data-sorted="false"] {
    &:before {
      content: "\f0dd";
    }
    color: black;
  }
  &[data-sorted="default"] {
    &:before {
      content: "\f0dc";
    }
  }
}

.table-search {
  border: 2px solid #c5c5c5;
  border-radius: 3px;
  float: right;
  margin-bottom: 10px;
  padding: 3px 7px;
}

tr.colored-dark-row.lane-section .btn {
  background: transparent !important;
}

.avatar-count {
  border-radius: 50px;
  margin-left: -32px;
  font-size: 20px;
  margin-top: -32px;
  margin-left: 20px;
  border-radius: 20px;
  z-index: 1;
}

.avatar-link {
  font-size: 13px;
  font-weight: 100;
}

.avatar-link:hover {
  background-color: #f7f7f7;
}

.table tr.lignt-border {
  border-color: #f4f4f4;
}

.ReactModalPortal > div {
  opacity: 0;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModalPortal .ReactModal__Overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  transition: opacity 80ms ease-in-out;
  background: rgba(0, 0, 0, 0.7) !important;
  z-index: 9999;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal {
  outline: none;
  text-align: center;
  display: inline-block;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  width: auto;
  height: auto;
  // padding: 20px;
  // border-radius: 4px;
  background: rgb(255, 255, 255) none repeat scroll 0% 0%;
  // border: 1px solid rgb(204, 204, 204);
}

.react-datepicker-wrapper {
  width: 100%;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0000001f;
  z-index: 99999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: #54ada2;
}

.sidebar-icon {
  margin-right: 10px;
  color: #26b99a;
}

.active,
.sidenav a:hover {
  .sidebar-icon {
    color: #808080;
  }
}

// .questions-text{
//   background-color: gray;
// }

.menu-profile-wrap {
  position: relative;

  .profile-image {
    width: 40px;
    height: 40px;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 3px 2px #61c3a9;
    }
  }

  .bottom {
    position: absolute;
    background: white;
    border: 2px solid #cecece;
    right: 0;
    top: 43px;
    width: auto;
    border-radius: 5px;
    min-width: 300px;

    .profile-image {
      width: 50px;
      height: 50px;
    }

    .info {
      font-size: 14px;
      color: gray;
      text-align: center;
      width: 100%;
      padding: 5px;
      display: inline-block;
      word-break: break-all;

      &.name {
        font-weight: bold;
        font-size: 15px;
      }

      .log-out {
        padding: 5px;
      }
    }
  }
}

.navbar-profile-chat-wrap {
  float: right;
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .col-auto {
    padding: 10px;
  }
}

/* For mobile phones: */
@media only screen and (max-width: 766px) {
  // .navbar {
  //   height: 136px;
  // }

  .msg-wrap .message-user-list {
    left: -206px;
  }

  .navbar-profile-chat-wrap {
    width: 100%;
    border-top: 1px solid #dbdbdb;
    margin-top: 12px;
    clear: both;
  }
}

@media screen and (max-width: 469px) {
}

.payment-iframe-wrap {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: #0000004f;
  justify-content: center;
  align-items: center;

  .content-area {
    // position: absolute;
    z-index: 1;
    background: white;
    width: calc(100% - 20px);
    max-width: 65rem;
    margin: 0 auto;
    height: auto;
    // bottom: calc(100vh - 313px - 10%);
    // left: 10px;
    // right: 10px;
    border-radius: 5px;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 469px) {
      height: 24rem;
      bottom: calc(100vh - 371px - 10%);
    }

    @media screen and (min-width: 768px) {
      position: relative;
    }

    iframe {
      width: 100%;
      min-height: 270px;
      overflow: auto;
    }
  }

  button.btn.btn-iframe-close {
    position: absolute;
    top: -26px;
    right: -26px;
    border-radius: 50%;
    background: black;
    color: white;
    margin: 30px;
  }
}

.pop {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #d3d3d39c;
  z-index: 1031;
  display: flex;
  justify-content: center;
  align-items: center;
  .content-area {
    background: white;
    padding: 15px;
    border-radius: 5px;
  }

  .big-text {
    font-weight: lighter;
    font-size: 50px;
  }
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
  justify-content: center;
}
.pagination > .active > a {
  background-color: #61c3a9;
  border-color: #61c3a9;
  color: #fff;
}
.pagination > li > a {
  border: 1px solid #61c3a9;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #61c3a9;
  border-color: #61c3a9;
  outline: none;
}
.pagination > li > a,
.pagination > li > span {
  color: #61c3a9;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}


// PCEYED Copmliance Section 9

#ComplianceColumn{
  display: grid;
  grid-template-columns: 10% 32% 22% auto;
  height: fit-content;
  font-weight: bold;
  font-size: 10px;
}

#ComplianceColumn{
  input{
      margin: 3px;
      margin-top: 0px;
  }
  .radio{
      margin-top: 20px;
      margin-left: -10px;
      text-align: center;
      label{
          margin-right: 14px;
      }
  }
  b{
      margin-top: 15px;
      font-weight: 1000;
      padding-left: 70px;
  }

  div{
      display:inline-block;
      margin-top: 15px;
      padding-left: 40px;
      h5{
        position: relative;
      }
  }

  
.gridColumn{
  display: inline-block;
  margin: -10px 0px;
  padding-top: 10px;
  
  background-color: #124449;
  color: white;
  height: 50px;
}

  .testing{
      margin-top: 15px;
      margin-left: 30px;
  }
}


#ComplianceColumn:first-child{
  margin-top: 0;
}
#ComplianceColumn:last-child{
  margin-bottom: 15px;
}

#ComplianceCard{
  display: inline-block;
  height: fit-content;
  border-color: #000000;
  border: 1px solid gray;
  border-radius: 17px;
  margin: 30px 0px;
  margin-bottom: 0;
}

.gridColumn:first-child{
  border-top-left-radius: 17px;

}

.gridColumn:nth-child(3){
  text-align: center;
}
.gridColumn:nth-child(4){
  text-align: center;
  border-top-right-radius: 17px;
}

.report{
  #logo{
      margin-top: 15px;
      margin-left: 70px;
  }
}

.Report-Information{
  text-align: center;
  margin-top: -35px;
}

.stores-select{
  border: none;
  background-color: gainsboro;
  // width: 240px;
}

.Report-Header{
  text-align: center;
  margin-top: 20px;
  font-size: 40px;
  margin-bottom: 20px
}

.cards{
  display: flex;
  justify-content: center;
  margin-top: 20px;
  .infocard{
      display: inline-block;
      width: 280px;
      height: 70px;
      border: 1px solid gray;
      border-radius: 6px;
      margin: 0px 20px;
  }
  img{
      height: 60px;
      display: inline-block;
      background-color: #e3e3e3;
      border-radius: 100%;
      margin-left: 15px;
      margin-top: -35px;
  }
  .infotext{
      display: inline-block;
      margin-left: 35px;
      margin-top: 5px;
      p{
          margin-top: -10px;
      }
  }
}

.compliancePost{
  background: rgb(246,137,54);
  background: linear-gradient(135deg, rgba(246,137,54,1) 0%, rgba(242,99,59,1) 66%, rgba(239,64,64,1) 100%);
  border: none;
  border-radius:10px;
  cursor: pointer;
  color:#ffffff;
  font-size:20px;
  padding:9px 42px;
  text-decoration:none;
  text-shadow:0px 1px 0px #2f6627;
  float: right;
  width: 150px;
  margin-right: 65px;
  margin-top: 20px;
}

.complianceSubmit{
  background: rgb(40,184,152);
  background: linear-gradient(135deg, rgba(40,184,152,1) 0%, rgba(79,197,222,1) 100%);
  display: block;
  border: none;
  border-radius:10px;
  cursor: pointer;
  color:#ffffff;
  font-size:20px;
  padding:9px 42px;
  text-decoration:none;
  text-shadow:0px 1px 0px #2f6627;
  float: right;
  width: 150px;
  margin-right: 60px;
  margin-top: 20px;
}

.complianceProcess{
  background: rgb(40,184,152);
  background: linear-gradient(135deg, rgba(40,184,152,1) 0%, rgba(79,197,222,1) 100%);
  display: block;
  border: none;
  border-radius:10px;
  cursor: pointer;
  color:#ffffff;
  font-size:20px;
  padding:9px 42px;
  text-decoration:none;
  text-shadow:0px 1px 0px #2f6627;
  float: right;
  margin-right: 60px;
  margin-top: 20px;
}

.printPDF{
  position: absolute;
  background: rgb(40,184,152);
  background: linear-gradient(135deg, rgba(40,184,152,1) 0%, rgba(79,197,222,1) 100%);
  border-radius:4px;
  border:1px solid #18ab29;
  display:inline-block;
  cursor: pointer;
  color:#ffffff;
  font-size: 15px;
  padding:9px 42px;
  text-decoration:none;
  text-shadow:0px 1px 0px #2f6627;
  margin-top: 10px;
  margin-right: 200px;
  right: 0;
  
}

/* Email Box */
#overlay{

  padding: 0px;
  margin: 0px;

  top:0;
  left:0;

  width: 100%;
  height: 100%;
  opacity: 70%;
  z-index: 100;
  
}

#opacityLayer{
  width: fit-content;
  height: fit-content;
}

  #email{
      z-index: 101;
      opacity: none;
      display: block;
      position: absolute;
      top:50%;
      left:50%;
      height: 600px;
      width: 400px;
      margin-top: -250px;
      margin-left: -200px;

      border-radius: 6px;
      border: 1px solid black;
      opacity: 100%;
      color: black;
      align-content: center;
      background-color: white;
      input{
          margin-left: 10%;
          width: 80%;
          height: 40px;
          margin-top: 20px;
          border-radius: 8px;
          border-width: 1px;
          background-color: #e8e8e8;
      }
      textarea{
          margin-left: 10%;
          width: 80%;
          height: 40px;
          margin-top: 20px;
          border-radius: 8px;
          border-width: 1px;
          background-color: #e8e8e8;
      }
      .body{
          height: 40%;
          max-width: 80%;
          word-wrap:break-word;
      }
      .sendEmail{
          margin-left: 10%;
          width: 80%;
          margin-top: 25px;
          border-width: 1px;
          height: 7%;
          border-radius: 8px;
          color: white;
          background-color: #3995d1;
      }
      .EmailHeader{
          font-weight: bold;
          text-align: center;
          margin-top: 20px;
          font-size: 27px;
          color: black;
          opacity: none;
      }
  }

  .d-inline{
    display: inline-block;
  }

  .filterButton {
    margin: 7px;
    border-radius:6px;
    border-color: white;
    display:inline-block;
    cursor:pointer;
    font-family:Arial;
    font-size: 10px;
    padding:3px 10px;
    color: black;
  }

  .filterButton:hover{
    border:1px solid #18ab29;
  }
