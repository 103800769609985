.full-layout {
    background-image: url(/assets/images/green-background.png);
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px;

    .white-box {
        background-color: white;
        width: 100%;
        padding: 25px;
        border-radius: 5px;
        align-self: flex-end;
    }

    .btn {
        background-color: #2e89bc;
        color: white;
        border-radius: 5px;
        width: 100%;
    }

    label.or {
        width: 100%;
        text-align: center;
        border-top: 1px solid black;
        height: 0;
        overflow: visible;
        line-height: 0;
        margin-bottom: 30px;
        display: inline-block;
        margin-top: 30px;
    }

    .google-btn {
        span {
            font-weight: bold !important;
        }
    }

    .password-rule {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            &::before {
                content: "\f111";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                display: block;
                float: left;
                margin: 7px 10px 0px 0;
                font-size: 8px;
            }
            &.active {
                font-weight: bold;
                color: #2eb99a;

                &::before {
                    content: "\f00c";
                    font-family: "Font Awesome 5 Free";
                }
            }
        }

        .not-valid {
            font-weight: bold;
            color: red;

            &::before {
                content: "\f00d";
                font-family: "Font Awesome 5 Free";
            }
        }
    }
}

.btnForPayment {
    color: white;
    border-radius: 5px;
    width: 100%;
    background-color: #2e89bc;
}

.btn-redirect {
    color: white;
    border-radius: 5px;
    position: absolute;
    top: 1rem;
    right: 2rem;
    width: 6rem;
    height: 2.5rem;
    background-color: #2e89bc;
    font-weight: bold;
    border-color: #2e89bc;
}

.btn-hide {
    display: none !important;
}

.add-store-btn:hover {
    background-color: #a0a0a0;
    border-color: #a0a0a0;
}

.drop-down-menu {
    border-color: #808080;
    border: revert;
    border-width: 0.1rem;
}

.drop-down-menu:focus {
    border-color: #80bdff;
}

//Desktop
@media only screen and (min-width: 768px) {
    .text-resize {
        font-size: 2.5rem;
    }

    .margin-resize {
        margin-right: 3.5rem;
    }

    .mw {
        min-width: max-content;
    }

    .justify-icons {
        justify-content: flex-start;
        display: flex;
        flex-direction: row;
    }
}

//Mobile
@media only screen and (max-width: 768px) {
    .store-white-box {
        margin-top: 3rem;
    }

    .lane-center {
        display: flex;
        justify-content: center;
    }

    .payment-stepper-right {
        margin-right: 6rem;
    }

    .ht {
        font-size: 1.4rem;
    }

    .justify-icons {
        display: flex;
        justify-content: space-around;
    }
}

//Mobile stepper css
.stepper-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    color: white;
    margin-top: 0.6rem;
}

.stepper-circle {
    width: 1.1rem;
    background-color: #9ea7a4f2;
    height: 1.1rem;
    border-radius: 1.1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0.3rem 0 0.3rem;
    font-size: 0.7rem;
}

.stepper-dash {
    color: #9ea7a4a8;
    font-size: 0.7rem;
    align-items: center;
    display: flex;
}

.stepper-circle-active {
    background-color: #42997f;
}

.stepper-dash-active {
    color: #42997f;
}

//Desktop stepper css
@media only screen and (min-width: 768px) {
    .stepper-container {
        margin-right: 2rem;
        justify-content: flex-end;
    }

    .stepper-circle {
        width: 2rem;
        height: 2rem;
        border-radius: 2rem;
        font-size: 0.9rem;
    }

    .stepper-dash {
        font-size: 3rem;
    }
}

// .login-wrapper-gray {
//     background: gainsboro;
//     min-height: 100vh;
//     margin: 0;
//     padding: 0;
//     float: left;
//     width: 100%;
// }

// .login-wrapper {
//     .center-screen {
//         margin-top: 30vh;
//         margin-bottom: 20vh;
//     }
//     .link-no-underline:hover {
//         text-decoration: none;
//         color: rgb(51, 51, 51);
//     }
//     .pcid-link {
//         color: grey;
//     }
//     .pcid-textbox {
//         border-radius: 0 !important;
//         border: none;
//         border-bottom: 2px solid #afaeae;
//     }
//     .pcid-textbox:focus {
//         border-bottom: 2px solid #000;
//         border-color: #757575;
//         outline: none;
//         box-shadow: none;
//     }
//     .btn-pcid {
//         border-radius: 25px;
//         background-image: linear-gradient(to right, #6ac07a, #5ec5bf);
//         border: none;
//     }
//     .btn-pcid:focus {
//         outline: none !important;
//         box-shadow: none !important;
//     }
//     .pcid-logo {
//         display: block;
//         margin: 0 auto;
//     }
// }
