@media screen and (max-width: 1421px) {
    #ComplianceColumn .radio {
        margin-left: auto !important;
        padding-left: initial;
    }

    #ComplianceColumn b {
        padding-left: 40px;
    }
}

@media screen and (max-width: 1278px) {
    .Doughnut {
        width: 360px;
    }
}



@media screen and (max-width: 1278px) {
    .DashboardGrid {
        max-height: 120px !important;
    }

    .dashboardBlocks2 {
        width: auto;
    }

    .dashboard .dashboardBlock span {
        margin-left: 9px;
    }

    .DashboardLegend {
        width: auto;
        margin-left: auto;
    }

    .Doughnut {
        width: 345px;
    }
}



@media screen and (max-width: 1181px) {
    .center-screen {
        margin-top: 10vh;
    }

    .safe-area {
        padding: 0;
        max-width: none;
    }

    .dashboard .DashboardActions {
        margin-left: -4vw;
    }

    .dashboard .dashboard-icon-wrap {
        font-size: 12px;
        padding: 0;
        margin-right: 20px;
        width: 137px;
    }

    .dashboard .dashboard-icon-wrap .icontext {
        padding: 5px 0 0 12px;
    }

    .dashboard .dashboardBlock {
        width: 80vw;
        margin-top: 30px;
    }

    .Doughnut {
        width: auto;
        margin-left: -32px;
        margin-top: 40px;
    }

    .dashboardBlocks2 {
        width: auto;
    }

    .DashboardLegend {
        display: flex;
        width: auto;
    }

    .dashboard .dashboardBlock span {
        margin-left: 9px;
    }

    .DashboardLegend div p {
        margin-left: 8px;
    }

    .dashboardTable {
        display: block;
    }

    .sidenav {
        width: 156px;
    }

    .sidenav a, .sidenavToggle {
        font-size: 14px;
        padding: 15px 5px 15px 11px;
    }


}



@media screen and (max-width: 1070px) {
    .Doughnut {
        width: 308px;
    }
}




@media screen and (max-width: 1025px) {
    .Doughnut {
        width: 275px;
    }
}



@media screen and (max-width: 982px) {
    .Doughnut {
        width: 250px;
    }
}